import { useState } from "react";

const useToggle = () => {
  const [showText, setShowText] = useState(false);

  const toggleShowText = (event) => {
    event.stopPropagation();
    setShowText((prevState) => !prevState);
  };

  return { showText, setShowText, toggleShowText };
};

export default useToggle;
