import { Button, Form, Input, Modal, message } from "antd";
import { useState } from "react";
import { useUserContext } from "../../context/UserContext";
import {
  deleteUser,
  EmailAuthCredential,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../../api/firebase.js";
import api from "../../api/api.js";
import { useNavigate } from "react-router";
import "./DeleteUser.styles.scss";

const DeleteUser = () => {
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { currentUser } = useUserContext();
  const [showForm, setShowForm] = useState(false);
  const user = auth.currentUser;

  const deleteUserData = async (user, values) => {
    try {
      const credential = EmailAuthProvider.credential(
        values.email,
        values.password
      );
      await reauthenticateWithCredential(user, credential);
      await deleteUser(user);
      await api.deleteUserData();
      navigate("/");
    } catch (error) {
      console.error("Error deleting user:", error);
      message.error("Error deleting user");
    }
  };

  const onFinish = (values) => {
    deleteUserData(user, values);
    form.resetFields();
  };
  const showConfirm = (values) => {
    if (values?.email !== currentUser?.email) {
      form.setFields([
        {
          name: "email",
          errors: ["Email does not match the current user's email"],
        },
      ]);
      return;
    }
    confirm({
      title: "Confirmation",
      content:
        "Are your sure you wish to proceed with a complete data deletion?",
      icon: null,
      okText: "Confirm",
      okButtonProps: {
        className: "delete-user-confirm",
      },
      okType: "text",
      cancelText: "Cancel",
      cancelButtonProps: { type: "text" },
      onOk() {
        onFinish(values);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <div className="delete-container">
      <h1 style={{ color: "white" }}>Delete your data</h1>

      {!showForm ? (
        <>
          <p className="delete-content">
            This process will unassign all your tasks, remove you from your
            current hub, and delete any relevant user information related to
            this email.
          </p>
          <h3 className="delete-confirmation">Would you like to continue?</h3>
          <Button
            className="caution-btn"
            shape="round"
            onClick={() => setShowForm(true)}
          >
            Proceed with Caution
          </Button>
        </>
      ) : (
        <Form
          form={form}
          name="basic"
          style={{
            maxWidth: 600,
          }}
          onFinish={(values) => showConfirm(values)}
          autoComplete="off"
        >
          <p className="form-content">
            Please re-enter your email & password to continue
          </p>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input placeholder="Enter your email here" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
            ]}
          >
            <Input.Password type="password" placeholder="Enter your password" />
          </Form.Item>

          <Button className="deleteData-btn" shape="round" htmlType="submit">
            Delete all data
          </Button>
          <Button
            className="back-btn"
            type="text"
            onClick={() => {
              setShowForm(false);
              form.resetFields();
            }}
          >
            Back
          </Button>
        </Form>
      )}
    </div>
  );
};

export default DeleteUser;
