import { Avatar, Button, Card, Divider, message } from "antd";
import profileAvatar from "../../assets/images/SVG/avatar.svg";
import api from "../../api/api";
import { useState } from "react";
import { useQueryClient } from "react-query";
import home from "../../assets/images/SVG/home.svg";
import home_blue from "../../assets/images/SVG/home_blue.svg";
import "./CrewSearchResult.styles.scss";
import UserProfileDetailsComponent from "../UserProfileDetails/UserProfileDetails.component";

const CrewSearchResult = ({ searchResult }) => {
  const queryClient = useQueryClient();
  const [invitedCrewIds, setInvitedCrewIds] = useState([]);
  const handleButtonClick = async (result) => {
    try {
      const response = await api.sendInvitationRequest({
        no_hub_crew: result.crew_id,
      });
      if (response?.status === 201) {
        setInvitedCrewIds((prevIds) => [...prevIds, result.crew_id]);
        queryClient.invalidateQueries("invitationRequestList");
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error.message;
      console.log(errorMessage);
      message.error(errorMessage || "An error occurred during invite");
    }
  };
  // Filter crew members with a hub and without a hub
  const inHub = searchResult?.filter(
    (result) => result?.hub_info?.hub_id !== null
  );
  const notInHub = searchResult?.filter(
    (result) => result?.hub_info?.hub_id === null
  );

  const renderCrewCards = (crewList, title) => {
    if (crewList.length === 0) return null;

    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="divider-container"
        >
          <Divider
            className="divider-background"
            style={{
              height: "2px",
              width: "38%",
            }}
          />
          <span
            style={{
              color: "white",
            }}
          >
            {title}
          </span>
          <Divider
            className="divider-background"
            style={{ height: "2px", width: "38%" }}
          />
        </div>
        {crewList.map((result) => {
          const ifEveryChoiceIsNull = result?.profile_details?.every(
            (detail) => detail[Object.keys(detail)[0]].choice === null
          );
          return (
            <Card hoverable className="result-card" key={result?.crew_id}>
              <Avatar
                size={48}
                className="avatar"
                src={
                  result?.profile_picture_url === null ||
                  result?.profile_picture_url.endsWith("default.jpg")
                    ? profileAvatar
                    : result?.profile_picture_url
                }
              />
              <div
                className={`content ${ifEveryChoiceIsNull && "no-icons"} ${
                  result?.hub_info?.hub_id && "no-button"
                } ${
                  ifEveryChoiceIsNull &&
                  result?.hub_info?.hub_id &&
                  "no-icons-button"
                }`}
              >
                <div className="name-section">
                  <h3 className="crew-name">{result?.full_name}</h3>
                  {result?.hub_info?.hub_id === null &&
                  result?.invitation_status === null ? (
                    <Button
                      className={`btn-${
                        invitedCrewIds.includes(result.crew_id)
                          ? "invited"
                          : "blue"
                      } invite-button`}
                      shape="round"
                      onClick={() => handleButtonClick(result)}
                      disabled={invitedCrewIds.includes(result.crew_id)}
                    >
                      {invitedCrewIds.includes(result.crew_id)
                        ? "Invited"
                        : "Send Invite"}
                    </Button>
                  ) : result?.hub_info?.hub_id === null &&
                    (result?.invitation_status === "invited" ||
                      result?.invitation_status === "requested") ? (
                    <Button
                      className="btn-invited
                invite-button"
                      shape="round"
                      disabled
                    >
                      Invited
                    </Button>
                  ) : null}
                </div>
                <div className="button-section">
                  <UserProfileDetailsComponent
                    details={result?.profile_details}
                  />
                  {result?.hub_info?.hub_id !== null ? (
                    <div className="hub center">
                      <img src={home} alt="home-icon" />
                      <span className="hub-name">
                        {result?.hub_info?.hub_name}
                      </span>
                    </div>
                  ) : (
                    <div className="hub">
                      <img src={home_blue} alt="home-icon" />
                      <span className="hub-name-blue">No Hub assigned</span>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          );
        })}
      </>
    );
  };

  return (
    <div className="crew-result">
      {renderCrewCards(notInHub, "Unassigned")}
      {renderCrewCards(inHub, "Assigned")}
    </div>
  );
};

export default CrewSearchResult;
