import {
  // Avatar,
  Button,
  Form,
  // Upload,
  message,
  Progress,
} from "antd";
import { useEffect, useReducer, useState } from "react";
// import profileAvatar from "../../../assets/images/SVG/avatar.svg";
// import editIcon from "../../../assets/images/SVG/edit.svg";
// import api from "../../../api/api";
// import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "react-query";
import api from "../../../api/api";
import useProfileFormConfig from "./hooks/useProfileFormConfig";
import "./ProfileForm.styles.scss";
import {
  ClickActions,
  handleClickReducer,
  initialState,
} from "./reducer/reducer";
import UserProfileDetailsComponent from "../../UserProfileDetails/UserProfileDetails.component";

const ProfileForm = ({ radioOptions }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const currentUser = queryClient.getQueryData("currentUser");
  const navigate = useNavigate();
  // const [avatarImage, setAvatarImage] = useState(null);
  const [completedPage, setCompletedPage] = useState(false);
  const profileDetails = queryClient.getQueryData("profileDetails");
  console.log({ profileDetails });
  const [state, dispatch] = useReducer(handleClickReducer, initialState);
  const formValues = state.formValues;
  const currentStage = state.currentStage;
  const currentItem = state.currentItem;
  const stages = useProfileFormConfig({
    currentUser,
    form,
    formValues,
    radioOptions,
    profileDetails,
  });
  console.log(state.skippedItems);

  // const userId = currentUser?.crew_id;
  // const profileImage =
  //   currentUser?.profile_picture_url === null ||
  //   currentUser?.profile_picture_url.endsWith("default.jpg")
  //     ? profileAvatar
  //     : currentUser?.profile_picture_url;

  // const getBase64 = (img, callback) => {
  //   const reader = new FileReader();
  //   reader.addEventListener("load", () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // };
  // const beforeUpload = (file) => {
  //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG file!");
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error("Image must smaller than 2MB!");
  //   }
  //   // return true;
  //   return isJpgOrPng && isLt2M;
  // };

  // const handleUploadChange = (info) => {
  //   if (info.file.status === "done") {
  //     getBase64(info.file.originFileObj, (url) => {
  //       setAvatarImage(url);
  //     });
  //   }
  // };

  // const customRequest = async (options) => {
  //   const { onSuccess, onError } = options;
  //   const formData = new FormData();
  //   formData.append(
  //     "profile_picture",
  //     form.getFieldValue("profile_picture")[0]?.originFileObj
  //   );

  //   try {
  //     const response = await api.uploadProfilePicture(userId, formData);
  //     onSuccess(response, formData);
  //     //   setAvatarImage(URL.createObjectURL(formData));
  //     queryClient.invalidateQueries("currentUser");
  //   } catch (error) {
  //     onError(error);
  //     message.error("error uploading profile picture");
  //   }
  // };

  // const formValues = useMemo(() => {
  //   if (formRef.current) {
  //     return form.getFieldsValue();
  //   }
  //   return [];
  // }, [form]);
  // console.log({ formValues });

  const isLastStageAndItem =
    currentStage === stages.length - 1 &&
    currentItem === stages[currentStage].formItems.length - 1;

  const isFirstStageAndItem = currentStage === 0 && currentItem === 0;

  const submitMutation = useMutation(
    (values) =>
      api.submitProfileDetails(
        { ...values, ...formValues },
        currentUser?.crew_id
      ),
    {
      onSuccess: (data) => {
        if (isLastStageAndItem) {
          setCompletedPage(true);
        }
        queryClient.setQueryData("profileDetails", data?.data);
        queryClient.invalidateQueries("profileDetails");
      },
      onError: (error) => {
        console.error("Form submission error:", error);
        message.error("Error in submitting profile details");
      },
    }
  );

  const handleNext = () => {
    form.validateFields().then((values) => {
      const stageItems = stages[currentStage]?.formItems;
      const key = Object.keys(values)?.find((key) => values[key] === undefined);
      if (key) {
        const stageItem = stageItems?.find((item) => item.name === key);
        if (
          stageItem &&
          stageItem?.component?.props?.initialValue !== undefined
        ) {
          values[key] = stageItem?.component?.props?.initialValue;
        }
      }

      if (
        stageItems?.length === currentItem + 1 ||
        (currentStage === stages?.length - 1 &&
          currentItem === stageItems?.length - 2)
      ) {
        submitMutation.mutate({ ...values, ...formValues });
      }

      dispatch({
        type: ClickActions.NEXT,
        stageItems: stageItems,
        stages: stages,
        values: { ...values, ...formValues },
      });
    });
  };

  const handleBack = () => {
    dispatch({
      type: ClickActions.BACK,
      stages: stages,
      currentUser: currentUser,
      navigate: navigate,
      values: { ...formValues },
    });
  };

  const clearAndskip = () => {
    form.validateFields().then((values) => {
      form.resetFields([values?.name]);
      dispatch({
        type: ClickActions.RESETandSKIP,
        stageItems: stages[currentStage].formItems,
        stages: stages,
        values: { ...values, ...formValues },
      });
    });
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      const finalValues = { ...values };
      submitMutation.mutate(finalValues);
    } catch (error) {
      console.log("Validation Failed:", error);
    }
  };

  const onLastSubmit = () => {
    message.success("Submitted profile details successfully");
    navigate(`/user/${currentUser?.crew_id}/settings`);
  };

  const renderProgressBars = () => {
    const totalStages = stages.length;
    const progressBars = [];

    for (let i = 0; i < totalStages; i++) {
      const totalItems = stages[i].formItems.length;
      const completedItems =
        i < currentStage
          ? totalItems
          : i === currentStage
          ? currentItem + 1
          : 0;
      const progressPercent = (completedItems / totalItems) * 100;

      progressBars.push(
        <Progress key={i} showInfo={false} percent={progressPercent} />
      );
    }

    return <div className="progress-bars">{progressBars}</div>;
  };

  // const upload = (
  //   <div className="avatar-container">
  //     <Avatar size={64} src={profileImage} />
  //     <div className="profile-edit-btn">
  //       <img width="10px" height="10px" src={editIcon} alt="edit-btn" />
  //     </div>
  //   </div>
  // );

  useEffect(() => {
    if (state.shouldNavigateToSettings) {
      navigate(`/user/${currentUser?.crew_id}/settings`);
    }
  }, [state.shouldNavigateToSettings, navigate, currentUser]);

  useEffect(() => {
    if (state.skippedItems?.length > 0) {
      console.log({ localStorage });
      const { stage, item } = state.skippedItems[0];
      dispatch({
        type: ClickActions.NAVIGATE_TO_SKIPPED,
        currentStage: stage,
        currentItem: item,
        skippedItems: state.skippedItems,
        values: { ...formValues },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profile-from-container">
      {renderProgressBars()}

      {!isLastStageAndItem && (
        <p className="stage-title">
          {currentStage === stages.length - 1
            ? `Final stage: ${stages[currentStage]?.title}`
            : `Stage ${currentStage + 1}: ${stages[currentStage]?.title}`}
        </p>
      )}

      <Form
        className="edit-user-form"
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        requiredMark={false}
      >
        {/* <Form.Item
        getValueFromEvent={(event) => {
          console.log({ event });
          return event?.fileList;
        }}
        className="profile-section"
        name="profile_picture"
      >
        <Upload
          showUploadList={false}
          accept=".jpeg, .jpg, .png"
          beforeUpload={beforeUpload}
          onChange={handleUploadChange}
          maxCount={1}
          // fileList={avatarImage ? [avatarImage] : []}
          customRequest={customRequest}
        >
          {avatarImage ? (
            <div className="avatar-container">
              <Avatar size={64} src={avatarImage} />
            </div>
          ) : (
            upload
          )}
        </Upload>
      </Form.Item>


      {/* } */}
        {!completedPage ? (
          <>
            {stages[currentStage]?.formItems.map((item, index) => {
              return (
                index === currentItem && (
                  <div
                    className={`form-item ${
                      isLastStageAndItem && "height-adjusted"
                    }`}
                    key={index}
                  >
                    <h1 className="input-heading">{item.input_heading}</h1>
                    {isLastStageAndItem && (
                      <>
                        <p style={{ textAlign: "center" }}>
                          {item?.additional}
                        </p>
                        <UserProfileDetailsComponent
                          userId={currentUser?.crew_id}
                          details={profileDetails?.profile_details}
                        />
                      </>
                    )}
                    <Form.Item name={item.name} rules={item.rules}>
                      {item.component}
                    </Form.Item>
                  </div>
                )
              );
            })}
            {isLastStageAndItem ? (
              <Button
                className="submit-button"
                type="primary"
                shape="round"
                htmlType="submit"
                loading={submitMutation?.isLoading}
              >
                Submit
              </Button>
            ) : (
              <>
                <div className="form-buttons">
                  <Button shape="round" onClick={handleBack}>
                    Back
                  </Button>
                  <Button
                    shape="round"
                    type="primary"
                    onClick={handleNext}
                    loading={submitMutation?.isLoading}
                  >
                    Next
                  </Button>
                </div>
                {!isFirstStageAndItem && (
                  <Button
                    className="clear-btn"
                    type="text"
                    onClick={clearAndskip}
                  >
                    Clear data & skip
                  </Button>
                )}
              </>
            )}
          </>
        ) : (
          <div
            className={`completed-page form-item ${
              isLastStageAndItem && "height-adjusted"
            }`}
          >
            {profileDetails?.completion_percentage !== 100 ? (
              <>
                <h1>Form Complete</h1>
                <p className="completed-page-content">
                  You have skipped some details. But you can always return to
                  fill them in!
                </p>
              </>
            ) : (
              <>
                <h1>Congatulations!</h1>
                <p className="completed-page-content">
                  You have completed your profile. This will improve your
                  visibility when home-hunting and match you with the perfect
                  roommates!
                </p>
              </>
            )}
            <UserProfileDetailsComponent
              details={profileDetails?.profile_details}
            />
            <Button
              className="submit-button"
              type="primary"
              shape="round"
              onClick={onLastSubmit}
            >
              Submit
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default ProfileForm;
