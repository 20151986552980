import { Form, Input, Radio } from "antd";

export const CustomRadioGroup = ({
  options,
  form,
  name,
  onChange,
  initialValue,
}) => {
  const className = `radio-button-group ${
    options?.length <= 3 ? "radio-flex" : "radio-grid"
  }`;

  const buttonClassName = `radio-button ${
    options?.length <= 3 ? "button-big" : "button-small"
  }`;

  return (
    <Radio.Group
      className={className}
      onChange={(e) => onChange(e, name)}
      value={form.getFieldValue(name) ?? initialValue}
    >
      {options?.map((item) => (
        <Radio.Button
          className={buttonClassName}
          key={item?.value}
          value={item?.value}
        >
          {item?.display}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export const FormInput = ({
  label,
  name,
  initialValue,
  placeholder,
  isTextArea,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={initialValue}
      className={isTextArea && "input-textArea"}
    >
      {isTextArea ? (
        <Input.TextArea
          autoSize={{
            minRows: 5,
          }}
          showCount
          maxLength={150}
          placeholder={placeholder}
        />
      ) : (
        <Input placeholder={placeholder} />
      )}
    </Form.Item>
  );
};
