import useToggle from "./hooks/useToggleProfileIcons";
import { Collapse } from "antd";
import "./UserProfileDetails.styles.scss";
import { useLocation } from "react-router";

const UserProfileDetailsComponent = ({ details }) => {
  const location = useLocation();
  const { showText, toggleShowText } = useToggle(false);
  const isUserInFormPath =
    location.pathname.includes("edit-profile") ||
    location.pathname.includes("settings");

  let className = "";

  if (isUserInFormPath) {
    className = "profile-details-container";
  } else {
    className = "profile-details-small";
  }

  const items = [
    {
      key: "1",
      label: !showText && (
        <div className="icon-section">
          {details?.map((item, index) => {
            const key = Object.keys(item)[0];
            const detail = item[key];

            return (
              detail.icon && (
                <img
                  className="icon"
                  key={index}
                  src={detail.icon}
                  alt="icon"
                />
              )
            );
          })}
        </div>
      ),
      children: (
        <div className="section">
          {details?.map((item, index) => {
            const key = Object.keys(item)[0];
            const detail = item[key];
            return (
              detail.icon && (
                <div className="item" key={index}>
                  <img className="icon" src={detail.icon} alt="icon" />
                  {showText && <p className="icon-text">{detail.text}</p>}
                </div>
              )
            );
          })}
        </div>
      ),
      showArrow: false,
    },
  ];

  return (
    <div className={className} onClick={toggleShowText}>
      <Collapse activeKey={showText ? "1" : null} ghost items={items} />
    </div>
  );
};

export default UserProfileDetailsComponent;
