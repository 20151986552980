import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ReactErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import UserProvider from "./context/UserContext";
import TagManager from "react-gtm-module";

// import ParticipantContextProvider from "./context/ParticipantContext";
// import { TaskDataProvider } from "./context/TaskContext";
// import UserProvider from "./context/UserContext";

const tagManagerArgs = {
  gtmId: "GTM-N5PG3HTR",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ReactErrorBoundary>
          <UserProvider>
            <App />
          </UserProvider>
        </ReactErrorBoundary>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
