import { CustomRadioGroup, FormInput } from "../ProfileFormInputContent";

export default function useProfileFormConfig({
  currentUser,
  form,
  formValues,
  radioOptions,
  profileDetails,
}) {
  function getOptions(optionType) {
    const optionList = radioOptions?.[optionType]?.map((item) => item);
    return optionList;
  }

  function setDefaultValue(optionType, index) {
    const optionList = radioOptions?.[optionType]?.map((item) => item);
    const defaultValue = optionList[index]?.value;
    return defaultValue;
  }

  function getInitialValue(optionType) {
    const item = profileDetails?.profile_details?.find(
      (item) => item[optionType]
    );
    const initialValue = item?.[optionType]?.choice;
    return initialValue;
  }

  function getInputOnchange(e, name) {
    form.setFieldValue(name, e.target.value);
  }

  const commonRadioProps = { onChange: getInputOnchange, form };

  return [
    {
      title: "Basic Information",
      formItems: [
        {
          input_heading: "Is this your name?",
          component: (
            <div>
              <FormInput
                label={"First Name"}
                name={"first_name"}
                initialValue={currentUser?.first_name}
                placeholder={"Enter your first name"}
              />
              <FormInput
                label={"First Name"}
                name={"last_name"}
                initialValue={currentUser?.last_name}
                placeholder={"Enter your last name"}
              />
            </div>
          ),
        },

        {
          input_heading: "What is your gender?",
          name: "gender",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("gender")}
              name="gender"
              initialValue={
                getInitialValue("gender") ??
                formValues?.gender ??
                setDefaultValue("gender", 2)
              }
            />
          ),
        },
        {
          input_heading: "How old are you?",
          name: "age",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("age")}
              name="age"
              initialValue={
                getInitialValue("age") ??
                formValues?.age ??
                setDefaultValue("age", 1)
              }
            />
          ),
        },
        {
          input_heading: "What is your occupational status?",
          name: "occupational_status",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("occupational_status")}
              name="occupational_status"
              initialValue={
                getInitialValue("occupational_status") ??
                formValues?.occupational_status ??
                setDefaultValue("occupational_status", 1)
              }
            />
          ),
        },
      ],
    },
    {
      title: "Lifestyle",
      formItems: [
        {
          input_heading: "Do you smoke?",
          name: "smoker",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("smoker")}
              name="smoker"
              initialValue={
                getInitialValue("smoker") ??
                formValues?.smoker ??
                setDefaultValue("smoker", 1)
              }
            />
          ),
        },
        {
          input_heading: "How often do you drink alcohol?",
          name: "alcohol",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("alcohol")}
              name="alcohol"
              initialValue={
                getInitialValue("alcohol") ??
                formValues?.alcohol ??
                setDefaultValue("alcohol", 0)
              }
            />
          ),
        },
        {
          input_heading: "How often do you socialize?",
          name: "social_interaction",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("social_interaction")}
              name="social_interaction"
              initialValue={
                getInitialValue("social_interaction") ??
                formValues?.social_interaction ??
                setDefaultValue("social_interaction", 0)
              }
            />
          ),
        },
        {
          input_heading: "How often do you cook?",
          name: "cook",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("cook")}
              name="cook"
              initialValue={
                getInitialValue("cook") ??
                formValues?.cook ??
                setDefaultValue("cook", 1)
              }
            />
          ),
        },
      ],
    },
    {
      title: "Home Preferences",
      formItems: [
        {
          input_heading: "Your pet policy?",
          name: "pets",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("pets")}
              name="pets"
              initialValue={
                getInitialValue("pets") ??
                formValues?.pets ??
                setDefaultValue("pets", 1)
              }
            />
          ),
        },
        {
          input_heading: "How clean do you prefer your home?",
          name: "cleanliness",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("cleanliness")}
              name="cleanliness"
              initialValue={
                getInitialValue("cleanliness") ??
                formValues?.cleanliness ??
                setDefaultValue("cleanliness", 1)
              }
            />
          ),
        },

        {
          input_heading: "How sensitive are you to noise?",
          name: "noise_sensitivity",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("noise_sensitivity")}
              name="noise_sensitivity"
              initialValue={
                getInitialValue("noise_sensitivity") ??
                formValues?.noise_sensitivity ??
                setDefaultValue("noise_sensitivity", 0)
              }
            />
          ),
        },
      ],
    },
    {
      title: "Work Preferences",
      formItems: [
        {
          input_heading: "Do you work from home?",
          name: "work_from_home",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("work_from_home")}
              name="work_from_home"
              initialValue={
                getInitialValue("work_from_home") ??
                formValues?.work_from_home ??
                setDefaultValue("work_from_home", 2)
              }
            />
          ),
        },
        {
          input_heading: "When do you work?",
          name: "shift_time",
          component: (
            <CustomRadioGroup
              {...commonRadioProps}
              options={getOptions("shift_time")}
              name="shift_time"
              initialValue={
                getInitialValue("shift_time") ??
                formValues?.shift_time ??
                setDefaultValue("shift_time", 0)
              }
            />
          ),
        },
        {
          input_heading: "Almost done!",
          additional: "These are your badges!",
          component: (
            <FormInput
              label="Describe yourself in a few words:"
              name="profile_description"
              placeholder="I’m a friendly person who loves to hang out with his roommates."
              isTextArea={true}
            />
          ),
        },
      ],
    },
  ];
}
