const skippedItems = JSON.parse(localStorage.getItem("skippedItems"));

export const initialState = {
  currentStage: skippedItems?.[0]?.stage ?? 0,
  currentItem: skippedItems?.[0]?.item ?? 0,
  formValues: {},
  skippedItems: skippedItems ?? [],
};

export const ClickActions = {
  NEXT: "next",
  BACK: "back",
  RESETandSKIP: "reset-skip",
  NAVIGATE_TO_SKIPPED: "naviagte_to_skipped",
};
export function handleClickReducer(state, action) {
  const stageItems = action?.stageItems;
  const stages = action?.stages;
  const values = action?.values;

  switch (action.type) {
    case ClickActions.NEXT: {
      console.log({ values });

      if (state.currentItem < stageItems?.length - 1) {
        return {
          ...state,
          currentItem: state.currentItem + 1,
          formValues: { ...values },
        };
      } else if (state.currentStage < stages?.length - 1) {
        const updatedSkippedItems = [...state.skippedItems];
        const checkIfValueUndefined = Object.values(state.formValues).some(
          (value) => value === undefined
        );
        console.log(checkIfValueUndefined);
        if (updatedSkippedItems.length > 0 && !checkIfValueUndefined) {
          updatedSkippedItems.shift();
          localStorage.setItem(
            "skippedItems",
            JSON.stringify(updatedSkippedItems)
          );
        }
        return {
          ...state,
          currentStage: state.currentStage + 1,
          currentItem: 0,
          formValues: { ...values },
          skippedItems: [...updatedSkippedItems],
        };
      } else {
        return { ...state };
      }
    }

    case ClickActions.BACK: {
      if (state.currentItem > 0) {
        return {
          ...state,
          currentItem: state.currentItem - 1,
          formValues: { ...values },
        };
      } else if (state.currentStage > 0) {
        return {
          ...state,
          currentStage: state.currentStage - 1,
          currentItem: stages[state?.currentStage - 1].formItems.length - 1,
          formValues: { ...values },
        };
      } else {
        return { ...state, shouldNavigateToSettings: true };
      }
    }
    case ClickActions.RESETandSKIP: {
      console.log({ values });
      const skippedItems = [
        ...state.skippedItems,
        {
          stage: state.currentStage,
          item: state.currentItem,
        },
      ];
      localStorage.setItem("skippedItems", JSON.stringify(skippedItems));

      if (state.currentItem < stageItems?.length - 1) {
        return {
          ...state,
          currentItem: state.currentItem + 1,
          formValues: { ...values },
          skippedItems,
        };
      } else if (state.currentStage < stages?.length - 1) {
        return {
          ...state,
          currentStage: state.currentStage + 1,
          currentItem: 0,
          formValues: { ...values },
          skippedItems,
        };
      } else {
        return { ...state };
      }
    }

    case ClickActions.NAVIGATE_TO_SKIPPED: {
      return {
        ...state,
        currentStage: action.currentStage,
        currentItem: action.currentItem,
      };
    }

    default:
      return { ...state };
  }
}
